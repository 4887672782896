import React from 'react';
import type { NextPage } from 'next';
import Head from 'next/head';
import Link from 'next/link';

const IndexPage: NextPage = () => {
  return (
    <>
      <Head>
        <title>Welcome to Users for YNAB!</title>
        <link rel="icon" href="/favicon.ico" />
        <link
          href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.2/dist/css/bootstrap.min.css"
          rel="stylesheet"
          integrity="sha384-Zenh87qX5JnK2Jl0vWa8Ck2rdkQ2Bzep5IDxbcnCeuOxjzrPF/et3URy9Bv1WTRi"
          crossOrigin="anonymous"
        ></link>
      </Head>
      <div className="container align-items-center">
        <h1>Welcome to the Users for YNAB app!</h1>
        <div>
          <Link href="/login">Log in</Link>
        </div>
        <div>
          <Link href="/signup">Sign Up</Link>
        </div>
        <div>
          <Link href="/privacy">Read our Privacy Policy</Link>
        </div>
      </div>
    </>
  );
};

export default IndexPage;
